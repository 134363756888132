.form {
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    justify-content: left;
    text-align: left;
    color: black;
}

.formGroup {
    margin-bottom: 1rem;
}

.formGroupNationality {
    margin-bottom: 1rem;
}

.label {
    margin-bottom: 5px;
    font-weight: bold;
}

.input {
    padding: 0.5rem;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 3px;
}

.select {
    padding: 0.6rem 0.6rem 0.6rem 0.8rem;
    margin-bottom: 0;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 3px;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="dimgray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 0.4rem top 50%;
    background-size: 1.4rem;
}

.select:hover {
    cursor: pointer;
    background-color: #e3e3e3;
}

.formGroupNationality .inputNationality {
    padding: 0rem;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    height: 100%;
}

.formGroupNationality .buttonNationality {
    padding: 0.35rem;
    padding-right: 1rem;
    font-size: 1rem;
    border-color: black;
    border-radius: 3px;
    color: black;
}

.formGroupNationality .buttonNationality:hover {
    background-color: #e3e3e3;
}

.error {
    color: #a60000;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    margin-left: 0.2rem;
}

.submitButton {
    padding: 10px 15px;
    font-size: 16px;
    background-color: #2fa6a6;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.submitButton:hover {
    background-color: #1f7a7a;
}