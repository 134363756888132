.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownButton {
    background-color: #eaefef;
    color: black;
    border: none;
    border-top: 1px solid #252930; /* Line above */
    border-bottom: 1px solid #252930; /* Line below */
    padding: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    white-space: nowrap;
    min-width: 250px;
    display: flex;
    justify-content: space-between; /* Align items to the left and right */
    align-items: center; /* Center items vertically */
    transition: background-color 0.25s ease; /* Add a transition effect */
}

.activeProfButton {
    font-weight: bold;
    font-size: 1.2rem;
    padding: 1rem;
}

.dropdownButton:hover {
    background-color: #d3dada;
}

.buttonIcon {
    color: black;
    font-size: 1rem;
    transition: color 0.25s ease;
}

.buttonIconBars {
    color: black;
    font-size: 1rem;
    transition: color 0.25s ease;
}

.buttonIconLink{
    display: flex;
    align-items: center;
    padding: 1rem;
}

.buttonIconLink:hover .buttonIconBars{
    color: #2fa6a6; /* Change color on hover */
}

.dropdownContent {
    display: block;
    position: absolute;
    background-color: white;
    min-width: 250px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 500;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
}

.dropdownContent ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdownContent ul li {
    margin: 0;
    border-top: 1px solid #ddd; /* Line above */
    border-bottom: 1px solid #ddd; /* Line below */
}

.dropdownContent ul li .prof {
    display: flex;
    justify-content: space-between; /* Align items to the left and right */
    align-items: center; /* Center items vertically */
    background: none;
    border: none;
    padding: 0px;
    padding-left: 12px;
    cursor: pointer;
    text-align: left;
    width: 100%;
    color: black;
    font-size: 1rem;
    margin: 0;
}

.dropdownContent ul li .prof:hover {
    background-color: #f1f1f1;
}

.iconLink{
    display: flex;
    align-items: center;
    padding: 16px;
}

.icon {
    color: darkgrey;
    font-size: 1rem;
    transition: color 0.25s ease;
}

.iconLink:hover .icon{
    color: #2fa6a6; /* Change color on hover */
}

.dropdownContent ul li .addProfileProf {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0px 0px 0px 12px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    color: black;
    font-size: 1rem;
    margin: 0;
}

.addProfileIconLink{
    display: flex;
    align-items: center;
    padding: 0.8rem;
}

.addProfileIcon {
    color: dimgrey;
    font-size: 1.2rem;
    transition: color 0.25s ease;
}

.addProfileProf:hover .addProfileIconLink .addProfileIcon{
    color: #2fa6a6;
}
