.numberButton {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); /* Centers the button exactly */
    z-index: 10;
    cursor: pointer;
    font-size: 2rem;
    width: 20%;
    height: 33%;
    border: none;
    border-radius: 8px;
    background-color: #419aff;
    color: white;
}

.numberButtonCenter {
    left: 50%;
}

.numberButtonLeft{
    left: 20%;
}

.numberButtonRight{
    left: 80%;
}