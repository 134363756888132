@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

.App {
  background-color: #FFFFFF;
  height: 100%;
  width: 100%;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 2px;
  margin-left: 2px;
}

button:hover {
  background-color: #0056b3;
}

h1 {
  margin-right: 5px;
  margin-left: 40px;
}