.popup {
    position: absolute;
    top: 100%;
    right: 0%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.popup .logoutButton, .popup .profileButton {
    color: white;
    border: none;
    padding: 0.9rem 1.8rem;
    cursor: pointer;
    border-radius: 4px;
    white-space: nowrap;
    margin-top: 15px;
    width: 100%;
    font-size: 1.1rem;
}

.popup .logoutButton {
    background-color: #c6716e;
}

.popup .profileButton {
    background-color: #2fa6a6;
}

.popup .logoutButton:hover {
    background-color: #a34a44;
}

.popup .profileButton:hover {
    background-color: #1f7a7a;
}
