.canvas {
    width: 100%;
    height: 100%;
}

.canvasContainer {
    display: flex; /* Enables flexbox layout */
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
    height: 50%; /* Ensures the container takes the full viewport height */
    width: 50%; /* Takes full width of the parent */
    left: 25%;
    position: relative; /* Allows absolutely positioned children */
}

.canvasContainerFull {
    display: flex; /* Enables flexbox layout */
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
    height: 100%; /* Ensures the container takes the full viewport height */
    width: 100%; /* Takes full width of the parent */
    position: relative; /* Allows absolutely positioned children */
}


.overlay {
    position: absolute;
    z-index: 10; /* Ensure it's above the canvas */
    background-color: rgba(0, 0, 0, 0);

}

.fullscreenButton {
    position: absolute;
    right: 0px;
    bottom: 6.5px;
}

.active {

}

.hidden {
    display: none;
}
