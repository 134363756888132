.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #8dcfcf;
    color: white;
    z-index: 0;
}

.headerContainer h1 {
    font-size: 2rem;
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
}

.profileDetailContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.profileDetail {
    padding: 20px;
    width: 50%;
    flex: 1;
}

.profileDetail h1 {
    font-size: 2rem;
    margin-bottom: 15px;
    margin-left: 20px;
}

.profileDetail p {
    font-size: 1rem;
    margin: 10px 0;
}

.profileScreen {
    display: flex;
}

.profileContent {
    flex: 1;
    padding: 20px;
    margin-left: 5vw;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}

.iconTrash {
    margin: 0;
    transition: transform 0.2s ease, color 0.25s ease; /* Add a transition effect */
    font-size: 2.3rem;
    padding: 20px;
    text-align: end;
    justify-content: center;
    align-items: center;
    display: flex;

}

.iconTrash:hover {
    color: #d10000;
    transform: scale(1.2);
    cursor: pointer;
}

.link {
    font-weight: bold;
    text-decoration: none;
    margin-right: 6px;
    color: #6366F1;
}

.link:hover {
    text-decoration: underline;
}