.startScreen {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%); /* Centers the button exactly */
    z-index: 10;
}

form {
    display: inline-block;
    margin-top: 2rem;
}

label {
    margin-right: 10px;
}

select {
    padding: 5px;
    font-size: 1rem;
    margin-right: 20px;
    margin-bottom: 20px;
}

.startGameButton {
    background-color: #2fa6a6;
}
.startGameButton:hover {
    background-color: #1f7a7a;
}

.guide{
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
}
.link {
    color: #2fa6a6;
    text-decoration: none;
}
.link:hover {
    text-decoration: underline;
}
