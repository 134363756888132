.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 3rem;
    background-color: #8dcfcf;
    color: white;
    z-index: 0;
}
.headerContainerMargin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 3rem;
    background-color: #8dcfcf;
    color: white;
    margin-bottom: 3rem;
    z-index: 0;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    text-align: center;
}

.containerNoSessions {
    display: flex;
    justify-content: center;
    align-items: end;
    height: 10vh;
    text-align: center;
    opacity: 0;
    transition: opacity 0.25s ease; /* Add transition */

}

.containerNoSessions.visible {
    opacity: 1; /* Fully visible */
}

.containerNoSessions.hidden {
    opacity: 0; /* Fully hidden */
}

.containerSessions {
    padding-top: 5px;
    display: flex;
    flex-direction: column; /* Change to column layout */
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto; /* Adjust height to auto */
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.25s ease; /* Add transition */
}
.containerSessions.visible {
    opacity: 1; /* Fully visible */
}

.containerSessions.hidden {
    opacity: 0; /* Fully hidden */
}

.p {
    font-size: 1.1rem;
}

.link {
    font-weight: bold;
    text-decoration: none;
    margin-right: 6px;
    color: #6366F1;
}

.link:hover {
    text-decoration: underline;
}

.linkEnd {
    font-weight: bold;
    text-decoration: none;
    margin-left: 6px;
    color: #6366F1;
}

.linkEnd:hover {
    text-decoration: underline;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center; /* Align text and icon horizontally */
    padding-bottom: 15px;
    gap: 10px; /* Adds spacing between text and icon */
    transition: transform 0.2s, border-bottom 0.1s;; /* Add a transition effect */
    width: 25%;
    margin: 60px auto;
    border-bottom: 2px solid #2fa6a6; /* Line below when not expanded */
}

.title h2 {
    margin: 0; /* Reset any default margin on h2 */
    padding: 0; /* Ensure no extra space */
    line-height: 1; /* Adjust line height if necessary */
}

.dropdown {
    display: flex;
    align-items: center;
    justify-content: center; /* Align text and icon horizontally */
    padding-bottom: 15px;
    gap: 10px; /* Adds spacing between text and icon */
    transition: transform 0.2s, border-bottom 0.1s;; /* Add a transition effect */
    width: 25%;
    margin: 15px auto;
}

.dropdown.expanded {
    border-bottom: none; /* Remove border when expanded */
}

.dropdown:not(.expanded) {
    border-bottom: 2px solid #2fa6a6; /* Line below when not expanded */
}

.dropdown h2 {
    margin: 0; /* Reset any default margin on h2 */
    padding: 0; /* Ensure no extra space */
    line-height: 1; /* Adjust line height if necessary */
}

.dropdown:hover {
    cursor: pointer;
    transform: scale(1.1); /* Slightly increase the size on hover */
}

.dropdown:hover .icon {
    color: #2fa6a6; /* Change color on hover */
}

.icon {
    line-height: 0; /* Adjust line height if necessary */
    font-size: 1.5rem;
    transition: color 0.25s ease; /* Add a transition effect */
}

.divider {
    width: 60%;
    height: 2px;
    background-color: #ccc; /* Adjust the color as needed */
    margin: 80px auto; /* Adjust the spacing as needed */
}