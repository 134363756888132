.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 18vh;
    padding: 0 5%;
}

.h1 {
    font-size: 40px;
    color: Black;
    margin-left: 0px;
}

.nav {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    position: sticky;
    top: 0;
    z-index: 10;
    align-items: center;
}

.nav .a {
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 99px;
    transition: 0.4s;
    color: Black;
    height: 3.25rem;
    display: flex;
    align-items: center;
}

.nav .a.active {
    background: #2fa6a6;
    color: white;
}
.nav .a:hover {
    background: #2fa6a6;
    color: white;
}
.nav:hover > .a:not(:hover) {
    background: none;
    color: black;
}

.navBtn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: black;
    display: none;
    font-size: 1.8rem;
    transition: background-color 0.25s ease;
}

.navBtn:hover {
    background-color: #1f7a7a;
}

.user {
    position: relative;
    padding: 12px 16px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 60;
    color: Black;
    z-index: 1000;
    display: flex;
}

.userText {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.userEmail {
    font-weight: bold;
}

.popup {
    position: absolute;
    top: 100%;
    left: 30%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.popup button {
    background-color: #2fa6a6;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
}

.popup button:hover {
    background-color: #1f7a7a;
}

.popupComponent {
    z-index: 1000;
}

.popupContainer {
    z-index: 1000;
}

@media (max-width: 1000px) {
    .open {
        transform: translateY(100vh);
        transition: 0.3s;
        min-height: auto;
    }

    .navBtn {
        display: flex;
    }

    .nav {
        z-index: 1000;
        top: -100vh;
        position: fixed;
        left: 0;
        height: 60%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0rem;
        background-color: #2fa6a6;
        z-index: 10;
        flex-wrap: wrap;
    }

    .nav .navCloseBtn {
        position: absolute;
        top: 2rem;
        right: 2rem;

    }

    .nav .a {
        font-size: 1.5rem;
    }
}