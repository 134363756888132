.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    height: 100vh;
    width: 100vw;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

.closeButton {
    line-height: 0; /* Adjust line height if necessary */
    font-size: 1.5rem;
    transition: color 0.25s ease; /* Add a transition effect */
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    padding: 0.7rem;
    cursor: pointer;
    color: black;
}

.closeButton:hover {
    color: #a60000; /* Change color on hover */
}