.RunIntroFirstScreen {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%); /* Centers the button exactly */
    z-index: 10;
}

.p {
    font-size: 2.5rem;
    margin: 0;
    padding: 0;
    text-align: center;
    color: black;
}

.p2 {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    text-align: center;
    color: black;
}